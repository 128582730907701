.Intro {
  background-image: url("../../img/home.png"); 
  height:100vh;
  width:100%;
}

.n-home {
  padding: 3rem 0px 0px 10rem;
  max-height: 1000px;
  
}

@media only screen and (max-device-width: 968px){
  .Intro {
  background-image: url("../../img/home-t.png"); 
    height: 20rem;
  }

  .n-home {
    max-width: 500px;
    padding: 2rem 0px 0px 8rem
  }
 
}
@media only screen and (max-device-width: 480px){
  .Intro {
    background-image: url("../../img/home-m.png"); 
    height: 11rem;
  }
  .n-home {
    max-width: 300px;
    padding: 20px 0px 0px 10px;
  }
}
