.grid-container {
  padding: 3rem 3rem 0 7rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
}

.cards {
  width: 20rem;
}

.mar-top {
  margin-top: 5rem;
}

@media screen and (max-width: 800px) {
  .services {
    flex-direction: column;
    gap: 5rem;
    padding: 0;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 17rem;
  }

  .cards>* {
    position: static;
  }

  .grid-container {
    padding: 0 1rem 0 1rem;
    margin-top: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .mar-top {
    margin-top: 3rem;
  }

}