.card {
  height: 20rem;
  width: 18rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  background: rgba(255, 255, 255, 0.26);
   border: 7px solid var(--grey); 
  box-shadow: 3px 3px 3px rgb(194, 230, 241);
  color:#0D5257 ;
  border-radius: 20px;
  padding: 2px 10px 10px 2px;
}

.card-txt {
  font-size: 25px;
  font-weight: normal;
  padding: 10px;

}

.card-sub-txt {
  font-size: 20px;
  font-weight: normal;
}

.card>img {
  width: 18rem;
  height: 12rem;
  margin-bottom: 15px;
}

.card>button {
  gap: 1rem;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .card {
    width: auto;
    margin-bottom: 1rem;
  }

  .card>img {
    width: auto;

  }
  .card-txt {
    font-size: 20px;
    font-weight: normal;
    padding: 10px;
  
  }
  
  .card-sub-txt {
    font-size: 15px;
    font-weight: normal;
  }
}