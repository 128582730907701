.n-wrapper {
  height: 10vh;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  top: 0;
}
.n-left {
  flex: 1;
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 2rem;
}
.n-button{
  gap: 5px;
  display: flex;
}
.n-slogan{
  max-width: 150px; 
  max-height: 150px; 
}
a{
  text-decoration: none;
  display: flex;
  color: white;
  font-size: medium;
}
.n-logo{
  max-width: 100px; 
  max-height: 100px; 
}
.n-name {
  font-size: 1.5rem;
  font-weight: bolder;
}
.n-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}
.n-font{
  font-size: 1rem;
  font-weight: bolder;
  color: black;
}
.n-list {
  flex: 10;
  
}
.n-list > ul {
  display: flex;
  gap: 2rem;
  margin-right: 4rem;
}

.n-list > ul :hover {
  color:#0D5257 ;
  cursor: pointer;
}

.n-button {
  flex: 2;
}

@media screen and (max-width: 800px) {
  .n-list {
    display: none;
  }
  .n-slogan {
    display: none;
  }
}
