.takeair {
  padding: 0 3rem 0 5rem;
  display: flex;
  height: 90vh;
  margin-top: 4rem;
  align-items: center;
}
.a-txt {
  color:#0D5257 ;
  font-size: 2rem;
  font-weight: normal;
}
.icon-s {
  width: 50px;
}

/* left side */
.awesome {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
}


.awesome-txt {
  color:#0D5257 ;
  font-size: 18px;
  margin-top: 1rem;
}

.m-button {
  margin-top: 2rem;
}

/* right side */
.w-right {
  position: relative;
}
.w-left {
  width:50%;
}

.w-mainCircle {
  left: 9rem;
  position: relative;
  width: 18rem;
  height: 18rem;
  border-radius: 100%;
  box-shadow: var(--smboxShadow);
  top: 2rem;
  background: white;
  /* darkMode */
  z-index: 2;
}

.w-mainCircle>* {
  position: absolute;
}

.w-mainCircle> :nth-child(1) {
  top: -3rem;
  left: 6rem;
}

.w-mainCircle> :nth-child(2) {
  left: -3rem;
  top: 5rem;
}

.w-mainCircle> :nth-child(3) {
  left: 6rem;
  top: 5rem;
}

.w-mainCircle> :nth-child(4) {
  left: 15rem;
  top: 5rem;
}

.w-mainCircle> :nth-child(5) {
  left: 6rem;
  top: 13rem;
}

.w-secCircle {
  width: 6rem;
  height: 6rem;
  position: absolute;
  border-radius: 100%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: "none";
  box-shadow: var(--smboxShadow);
  background: white;
}

.w-secCircle>img {
  transform: scale(0.6);
}

.w-backCircle {
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 100%;
  /* darkMode */
  z-index: 1;
}

.lightCircle {
  background: #279989;
  left: 18rem;
  top: 0rem;
}

.darkCircle {
  background: #0D5257;
  left: 18rem;
  top: 8rem;
}

@media screen and (max-width: 800px) {
  .takeair {
    flex-direction: column;
    height: 38rem;
    padding: 0;
    gap: 5rem;
    margin-bottom: 0%;
  }
  .w-left {
    width:100%;
  }
  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content: start;
    transform: scale(0.7);
  }

  .w-mainCircle {
    position: static;
  }

  .w-backCircle {
    left: 8rem !important;
  }

  .m-button {
    margin-left: 4rem;
    gap: 5px;
    display: flex;
  }
  .a-txt {
    color: var(--black);
    font-size: 1rem;
    font-weight: normal;
  }
  .awesome {
    margin: 1rem;
    
  }
  .awesome-txt {
    font-size: 12px;
  }
}