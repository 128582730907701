
.App {
  overflow: hidden;
}
.blur{
  top: "17rem";
  left: "-9rem";
  position: absolute;
  border-radius: 20%;
  filter: blur(212px);
  z-index: -9;
  filter: blur(72px);
  position: absolute;
  width: 77rem;
  height: 36rem;
  background: #279989 0.71%;
}
.head-txt {
  margin: 8% 2% 2% 9%;
  font-size: 2rem;
  font-weight: normal;
  color:#0D5257 ;
}


.button {
  background: linear-gradient(180deg, #279989 26.71%, #0D5257 99.36%);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
}

.button:hover {
  cursor: pointer;
  border: 1px solid var(#203520);
  color: var(#203520);
}


@media only screen and (max-device-width: 968px){
  .head-txt {
    font-size: 25px;
    margin: 4% 1% 1% 6%;
  }
  .blur {
    width: 19rem;
    height: 60rem;
  }
 
}
@media only screen and (max-device-width: 480px){
  .head-txt {
    font-size: 20px;
    margin: 4% 1% 1% 6%;
  }
  .blur {
    width: 10rem;
    height: 130rem;
  }
}