.footer {
  display: flex;
  align-items: center;
  margin: -0.5rem -8.5rem;
  position: relative;
}
.f-link{
  color: white;
  font-size: larger;
  font-weight: bold;
  text-decoration: none;
}
.f-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 12rem;
  gap: 2rem;
  color: white;
}

.f-icons {
  display: flex;
  gap: 2rem;
}

@media screen and (max-width: 800px) {
  .f-content {
    transform: scale(.5);
    margin-top: 4rem;
    gap: 1rem;
  }
  .f-icons {
    display: flex;
    gap: 1rem;
  }
}